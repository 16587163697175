import { useState } from "react"
import { URL_BASE } from "../../utils/consts"

const useGetYoutubeSummary = (mindId) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [videoId, setVideoId] = useState("")

  const getSummary = async (e, link) => {
    e.preventDefault()
    let videoIdInput = link

    if (link.includes("youtube.com/watch?v=")) {
      videoIdInput = link.split("v=")[1]
      const ampersandPosition = videoIdInput.indexOf("&")
      if (ampersandPosition !== -1) {
        videoIdInput = videoIdInput.substring(0, ampersandPosition)
      }
    } else if (link.includes("youtu.be/")) {
      videoIdInput = link.split("youtu.be/")[1]
    }

    setVideoId(videoIdInput)
    setLoading(true)
    setError(null)

    try {
      const token = localStorage.getItem("token")
      const response = await fetch(
        URL_BASE + `/gpt/summary/${videoIdInput}/${mindId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (!response.ok) {
        throw new Error("Error al obtener el resumen del video")
      }
      const data = await response.json()
      console.log(data.summary)
      setData(data?.summary)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return { data, error, loading, videoId, getSummary }
}

export default useGetYoutubeSummary
