import { useEffect, useState } from "react"
import { URL_BASE } from "../../utils/consts"

export default (product) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const fetchMinds = async () => {
    const token = localStorage.getItem("token")
    try {
      const response = await fetch(URL_BASE + `/mind/product/${product}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()
      setData(data?.minds)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const refetch = fetchMinds

  useEffect(() => {
    fetchMinds()
  }, [])

  return { data, error, loading, refetch }
}
