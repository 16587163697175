import React, { useEffect, useState } from "react"
import {
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from "@material-ui/core"
import ReactMarkdown from "react-markdown"
import { NavbarLayout } from "../layouts/AppLayout"
import AuthorizedPageWrapper from "../context/AuthorizedPageWrapper"
import { Typography } from "instadrops-ui"
import { URL_BASE } from "../utils/consts"
import { YOUTUBE_SUMMARY_NAME } from "../constants/products"
import useGetYoutubeSummary from "../hooks/youtube/useGetYoutubeSummary"
import useGetMindProducts from "../hooks/mind/useGetMindProducts"
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core"
import { FaBrain } from "react-icons/fa6"
const ArticleBender = () => {
  const [openChat, setOpenChat] = useState(false)
  return (
    <NavbarLayout
      setOpenChat={setOpenChat}
      openChat={openChat}
      product={YOUTUBE_SUMMARY_NAME}
    >
      <AuthorizedPageWrapper>
        <App />
      </AuthorizedPageWrapper>
    </NavbarLayout>
  )
}

const App = () => {
  const [link, setLink] = useState("")
  const [selectedMind, setSelectedMind] = useState("")
  const {
    data: markdownText,
    error: videoSummaryError,
    loading: videoSummaryLoading,
    videoId,
    getSummary,
  } = useGetYoutubeSummary(selectedMind)
  const { data: minds } = useGetMindProducts("YoutubeSummary")

  useEffect(() => {
    if (minds && minds.length > 0) {
      setSelectedMind(minds[0]._id)
    }
  }, [minds])

  const handleSubmit = async (e) => {
    getSummary(e, link)
  }
  const handleMindChange = (event) => {
    setSelectedMind(event.target.value)
  }

  return (
    <Grid container justify="center">
      <Grid item xs={8}>
        <Typography
          style={{ textAlign: "center", fontWeight: 800, marginBottom: 24 }}
          variant="h2"
        >
          Resumen tu video de Youtube
        </Typography>
        <Grid container justify="flex-start" style={{ marginBottom: 24 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="mind-select-label">
                <FaBrain /> Selecciona un agente
              </InputLabel>
              <Select
                labelId="mind-select-label"
                value={selectedMind}
                onChange={handleMindChange}
              >
                {minds?.map((mind) => (
                  <MenuItem key={mind._id} value={mind._id}>
                    {mind.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Grid>
        <div style={{ marginTop: 36 }}>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Link de YouTube"
                  variant="outlined"
                  fullWidth
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} container alignItems="center">
                <Button type="submit" variant="contained" color="primary">
                  <Typography style={{ fontWeight: 800 }}>
                    Generar Resumen
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </form>

          {videoId && (
            <Card
              elevation={3}
              style={{ minHeight: 500, marginTop: 36, padding: 48 }}
            >
              <CardContent>
                {markdownText && (
                  <>
                    <Grid justify="center">
                      <Typography>¿Te interesó este video?</Typography>
                      <Button color="primary">
                        Cargar al Gestor Documental
                      </Button>
                      <Button color="primary">Informar al equipo</Button>
                    </Grid>
                    <Typography>
                      <ReactMarkdown>{markdownText}</ReactMarkdown>
                    </Typography>
                  </>
                )}{" "}
                {videoSummaryError && (
                  <Typography color="error">{videoSummaryError}</Typography>
                )}
                {videoSummaryLoading && (
                  <Grid container justify="center" alignItems="center">
                    <CircularProgress />
                  </Grid>
                )}
              </CardContent>
            </Card>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default ArticleBender
